import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  LOGGEDINUSER, UserAgentService, User, AppInitializerService, AppService, CannyService, ContentfulService, GTagService, SpeedDialService, NavigationHelper, FeatureFlags, LaunchDarklyService,
} from '@lc/core';
import { Router } from '@angular/router';
import {
  map, switchMap, shareReplay, filter, take,
} from 'rxjs/operators';
// tslint:disable-next-line: nx-enforce-module-boundaries
import { Observable } from 'rxjs';
import { environment } from '../../../../libs/environments/environment';
import { options } from './five9.service';

@Component({
  selector: 'lc-agent-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})

export class AppComponent {
  omniChat$: Observable<Boolean>;
  private readonly GTAG_MAP = new Map([
    ['prod', 'GTM-5C5WLC2'],
    ['uat', 'GTM-K6MXBZ4'],
    ['local', 'GTM-W8JCFCV'],
  ]);

  constructor(
    private appInitializer: AppInitializerService,
    store: Store<any>,
    router: Router,
    private gtagService: GTagService,
    private speedDialService: SpeedDialService,
    userAgentService: UserAgentService,
    _contentfulService: ContentfulService,
    private cannyService: CannyService,
    private launchDarklyService: LaunchDarklyService,
  ) {
    userAgentService.doBrowserCheck();
    appInitializer.initializeKeys('dc-user-app', AppService.productSuiteName);

    // Listen for router changes and notifiy the gtag service when the route changes
    const user$: Observable<User> = store.select(LOGGEDINUSER).pipe(shareReplay(1));
    // Set the canny SSO after user logged in
    user$.pipe(filter((user) => !!user), take(1)).toPromise()
      .then(() => {
        this.omniChat$ = this.launchDarklyService.getFeature$(FeatureFlags.OMNI_CHAT, false);
        this.cannyService.getCannySSO$().toPromise();
      });

    NavigationHelper.urlChanged$(router).pipe(
      switchMap((url) => user$.pipe(map((user) => ({ user, url })))),
    ).subscribe(({ user, url }) => {
      // Update the google tag service
      this.gtagService.updateGTag(this.GTAG_MAP, user, url, 'Agent');

      // Refresh notifications
      this.appInitializer.refreshNotifications(url);
    }, (error) => { throw new Error(error); });

    // Intialize the Speed Dial
    this.speedDialService.setSpeedDial({
      five9Config: options,
      feedbackFn: () => router.navigate(['feedback']),
      isProduction: environment.production,
    });
  }
}
